<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="8"
        class="pr-0"
      >
        <v-file-input
          v-model="file"
          name="file"
          :label="title"
          :rules="isRequired ? [fileInputModel ? true : '这是必选项'] : [!file || fileInputModel ? true : '已选择文件，请先上传或清空文件']"
          :accept="acceptType"
          :disabled="isDisabled"
          outlined
          dense
          hide-details="auto"
          clearable
          :value="fileInputModel"
          @change="changeFile"
        >
        </v-file-input>
      </v-col>
      <v-col
        v-if="!isDisabled"
        cols="12"
        md="4"
      >
        <div class="d-flex">
          <v-btn
            v-if="!isShowProgressBar"
            color="success"
            class="me-2 mt-1"
            small
            :disabled="!showVideoUpload"
            @click="uploadFile"
          >
            上传
          </v-btn>
          <v-btn
            v-if="isShowProgressBar && !isPaused"
            outlined
            small
            class="me-2 mt-1"
            @click="uploadPause"
          >
            暂停
          </v-btn>
          <v-btn
            v-if="isShowProgressBar && isPaused"
            outlined
            small
            class="me-2 mt-1"
            @click="uploadResume"
          >
            继续
          </v-btn>
          <v-btn
            v-if="isShowProgressBar"
            class="me-4 mt-1"
            small
            color="error"
            @click="uploadCancel"
          >
            取消
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isShowProgressBar"
      :value="progress.value"
      height="20"
      striped
      class="mt-2"
    >
      {{ Math.ceil(progress.value) }}%
    </v-progress-linear>

    <div
      v-if="!!fileInputModel"
    >
      <v-tooltip
        top
      >
        <template #activator="{ on, attrs }">
          <v-chip
            text-color="primary"
            color="primary"
            class="mt-2 mx-2"
            v-bind="attrs"
            :disabled="isDisabled"
            v-on="on"
            @click="uploadFileDelete()"
          >
            {{ fileName }}
          </v-chip>
        </template>
        <div>点击删除该上传文件</div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  modalFail,
  toastFail, toastInfo, toastSuccess,
} from '@core/utils/prompt'
import { required } from '@core/utils/validation'

// import {
//   fetchOSS, progress, fileUploadMulti,
//   fileUploadPause, fileUploadCancel,
//   list,
// } from '@core/utils/useAliOSS'
import { AliOSS } from '@core/utils/useAliOSS'

export default {
  name: 'FileInputSingle',
  model: {
    prop: 'fileInputModel',
    event: 'update:file-input-model',
  },
  props: {
    fileDetail: {
      type: Object,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    fileInputModel: {
      type: [String, Array],
      required: true,
    },
    acceptType: {
      type: String,
      required: false,
      default: '',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '请上传文件',
    },
    showVideoUpload: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const aliOss = new AliOSS()
    const file = ref(null)
    const isShowProgressBar = ref(false)
    const isPaused = ref(false)
    const isUploaded = ref(false)
    const fileUploadedKey = ref('')
    const fileDetails = ref({ ...props.fileDetail })
    const userId = JSON.parse(localStorage.getItem('userData')).id
    const progress = computed(() => aliOss.progressBar)

    aliOss.fetchOSS()

    // fetchOSS()

    /* 获取文件前缀 */
    function getPrefixPath() {
      const date = new Date()

      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${userId}/${date.valueOf()}`
    }

    // 文件上传（覆盖同名文件）
    function fileUpload() {
      if (file.value) {
        const data = file.value
        fileDetails.value.size = data.size
        fileDetails.value.name = data.name
        fileDetails.value.type = data.name.endsWith('.srt') ? 'srt' : data.type
        const fileNameUpload = `${getPrefixPath()}_${data.name}`
        aliOss.fileUploadMulti(fileNameUpload, data).then(response => {
          /* 判断文件是否上传成功(阿里云上是否有同名文件) */
          aliOss.list(fileNameUpload).then(result => {
            if (result.objects.length) {
              /* 阿里云中存在同名文件（上传成功） */
              fileUploadedKey.value = fileNameUpload

              // fileDetails.value.key = fileUploadedKey.value
              context.emit('update:file-input-model', fileUploadedKey.value)
              context.emit('update:file-name', file.value.name)
              context.emit('update:file-detail', fileDetails.value)
              context.emit('update:bucket', aliOss.alioss.bucket)
              toastSuccess(`${data.name} 文件上传完成`)
              isShowProgressBar.value = false
              isPaused.value = false
              isUploaded.value = true
            } else {
              /* 在阿里云OSS中找不到对应文件，上传失败 */
              modalFail('文件上传失败，请重新上传！')
              isShowProgressBar.value = false
              isPaused.value = false
            }
          }).catch(e => {
            /* 查询阿里云OSS中对应文件失败，也认为上传失败 */
            console.log(e)
            modalFail('文件上传失败，请重新上传！')
            isShowProgressBar.value = false
            isPaused.value = false
          })
        }).catch(error => {
          console.log(error)

          // 取消上传
          if (error.name === 'cancel') return
          modalFail('上传失败，请重试!')
          isShowProgressBar.value = false
          isPaused.value = false
        })
      }
    }

    const uploadFile = () => {
      if (!file.value) {
        toastInfo('还未选择文件')

        return
      }

      if (props.acceptType && props.acceptType !== '*') {
        const suffix = `.${file.value.name.split('.').pop().toLowerCase()}`
        console.log(suffix)
        if (!props.acceptType.split(',').includes(suffix)) {
          toastInfo('请上传指定文件！')

          return
        }
      }
      toastSuccess('开始上传')
      console.log(file.value)
      isShowProgressBar.value = true
      fileUpload()
    }

    const uploadPause = () => {
      try {
        // 暂停上传。
        aliOss.fileUploadPause()
        toastInfo('暂停上传')
        isPaused.value = true
      } catch (e) {
        toastInfo('暂停上传失败，请重试')
        console.log(e)
      }
    }

    const uploadResume = () => {
      toastInfo('继续上传')
      isPaused.value = false
      fileUpload()
    }

    const uploadCancel = () => {
      // 取消上传。
      aliOss.fileUploadCancel().then(res => {
        toastFail('取消上传')
        console.log(res)
        isShowProgressBar.value = false
        isPaused.value = false
      }).catch(error => {
        toastFail('取消上传失败，请重试')
        console.log(error)
      })
    }

    const uploadFileDelete = () => {
      // fileDelete(filesUploaded.value[fileIndex].key).then(response => {
      //   console.log(response)
      context.emit('update:file-input-model', '')
      toastSuccess('成功删除文件')
      isUploaded.value = false
      isShowProgressBar.value = false

      // }).catch(error => {
      //   console.log(error)
      //   modalFail('删除失败')
      // })
    }

    function consoleTest(...mes) {
      console.log(...mes)
    }

    function changeFile(files) {
      context.emit('changeFile', files)
    }

    return {
      file,
      progress,
      isPaused,
      isUploaded,
      isShowProgressBar,
      uploadFileDelete,
      uploadFile,
      uploadPause,
      uploadResume,
      uploadCancel,
      consoleTest,

      // validator
      validator: { required },
      changeFile,
    }
  },
}
</script>

<style scoped>

</style>
