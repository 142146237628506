<template>
  <v-navigation-drawer
    :value="isAddNewSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">视频上传</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-select
            v-model="submitData.channelId"
            :rules="[validators.required]"
            :items="channelList"
            outlined
            dense
            clearable
            label="频道名"
            item-text="title"
            placeholder="频道名"
            hide-details="auto"
            item-value="pageId"
            no-data-text="暂无频道"
            class="mb-6"
          ></v-select>

          <v-text-field
            v-model="submitData.videoName"
            :rules="[validators.required]"
            outlined
            dense
            clearable
            label="视频名字"
            placeholder="视频名字"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <file-input-single
            :file-input-model.sync="submitData.video"
            :file-name.sync="fileNameVideo"
            :file-detail.sync="fileDetailVideo"
            accept-type=".mov,.mpeg-1,.mpeg-2,.mpeg4,.mp4,.mpg,.avi,.wmv,.mpegps,.flv"
            title="上传视频"
            class="mb-4"
          ></file-input-single>

          <file-input-single
            :file-input-model.sync="submitData.thumbnail"
            :file-name.sync="fileNameThumbnail"
            :file-detail.sync="fileDetailThumbnail"
            :is-required="false"
            accept-type=".jpg,.jpeg,.png"
            title="上传缩略图"
            class="mb-4"
          ></file-input-single>

          <file-input-single
            :file-input-model.sync="submitData.subtitle"
            :file-name.sync="fileNameSubtitle"
            :file-detail.sync="fileDetailSubtitle"
            :is-required="false"
            accept-type=".srt"
            title="上传字幕文件"
            class="mb-4"
          ></file-input-single>

          <div class="d-flex">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              提交
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetData"
            >
              取消
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { ref } from '@vue/composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import FileInputSingle from '@/views/components/file-input/FileInputSingle.vue'
import store from '@/store'
import storeModule from './storeModule'

export default {
  components: {
    FileInputSingle,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    channelList: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'video-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_ATTR_STORE_MODULE_NAME)) store.unregisterModule(POINT_ATTR_STORE_MODULE_NAME)
    // })

    const blankData = {
      channelId: '',
      videoName: '',
      versionNumber: '',
      video: '',
      subtitle: '',
      thumbnail: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const fileNameVideo = ref('')
    const fileNameThumbnail = ref('')
    const fileNameSubtitle = ref('')
    const fileDetailVideo = ref({})
    const fileDetailThumbnail = ref({})
    const fileDetailSubtitle = ref({})
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-new-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        const randomDec = () => Math.floor(Math.random() * 10)
        fileDetailVideo.value.videoKey = submitData.value.video
        fileDetailSubtitle.value.videoKey = submitData.value.subtitle
        fileDetailThumbnail.value.videoKey = submitData.value.thumbnail
        console.log({
          channelId: submitData.value.channelId,
          videoName: submitData.value.videoName,
          video: fileDetailVideo.value,
          subtitle: fileDetailSubtitle.value,
          thumbnail: fileDetailThumbnail.value,
        })
        store.dispatch('video-list/uploadVideo', {
          channelId: submitData.value.channelId,
          videoName: submitData.value.videoName,
          video: fileDetailVideo.value,
          subtitle: fileDetailSubtitle.value,
          thumbnail: fileDetailThumbnail.value,
          versionNumber: `${(new Date()).valueOf() * 100}${randomDec()}${randomDec()}`,
        }).then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toastSuccess('提交成功！')
          resetData()
        }).catch(error => {
          modalFail(error.response.data.message)
          console.log(error.response)
        })
      } else {
        validate()
      }
    }

    return {
      onSubmit,
      validate,
      resetData,
      form,
      valid,
      submitData,
      fileNameVideo,
      fileNameThumbnail,
      fileNameSubtitle,
      fileDetailVideo,
      fileDetailThumbnail,
      fileDetailSubtitle,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
