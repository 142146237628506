<template>
  <div>
    <upload
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :channel-list="channelList"
      @refetch-data="fetchList()"
    ></upload>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          视频上传
        </slot>
        <v-spacer></v-spacer>

        <v-row
          class="my-1"
        >
          <v-col
            md="2"
            cols="12"
          >
            <v-text-field
              v-model="channelId"
              placeholder="频道ID"
              single-line
              dense
              outlined
              hide-details
              class="invoice-list-search"
              @keydown.enter="search"
            />
          </v-col>

          <v-col
            md="2"
            cols="12"
          >
            <v-text-field
              v-model="channelName"
              placeholder="频道名"
              single-line
              dense
              outlined
              hide-details
              class="invoice-list-search"
              @keydown.enter="search"
            />
          </v-col>

          <v-col
            md="2"
            cols="12"
          >
            <v-text-field
              v-model="versionNumber"
              placeholder="视频编号"
              single-line
              dense
              outlined
              hide-details
              class="invoice-list-search"
              @keydown.enter="search"
            />
          </v-col>

          <v-col
            md="2"
            cols="12"
          >
            <v-text-field
              v-model="videoName"
              placeholder="视频名字"
              single-line
              dense
              outlined
              hide-details
              class="invoice-list-search"
              @keydown.enter="search"
            />
          </v-col>

          <v-col
            md="4"
            cols="12"
          >
            <div
              class="d-flex justify-end"
            >
              <v-btn
                color="primary"
                dark
                class="me-3"
                @click="search"
              >
                查询
              </v-btn>

              <v-btn
                color="success"
                dark
                class="me-3"
                @click="clickUpload"
              >
                上传
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="headers"
              :items="dataList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <template #[`item.channelName`]="{item}">
                {{ item.fbName || item.title }}
              </template>

              <template #[`item.video`]="{item}">
                {{ item.videoNames.filter(v => videoFilter(v.split('.').pop())).join() }}
              </template>

              <template #[`item.thumbnail`]="{item}">
                {{ item.videoNames.filter(v => thumbnailFilter(v.split('.').pop())).join() }}
              </template>

              <template #[`item.subtitle`]="{item}">
                {{ item.videoNames.filter(v => subtitleFilter(v.split('.').pop())).join() }}
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Upload from '@/views/project/video-upload/Upload.vue'
import storeModule from './storeModule'
import useList from './useList'

export default {
  name: 'VideoUpload',
  components: {
    Upload,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const STORE_MODULE_NAME = 'video-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const generalData = ref({})
    const isPlanUpgradeDialogVisible = ref(false)
    const isAddNewSidebarActive = ref(false)

    const {
      fetchList,
      fetchChannelList,
      videoFilter,
      subtitleFilter,
      thumbnailFilter,
      totalLists,
      dataList,
      options,
      headers,
      loading,
      channelId,
      channelName,
      channelList,
      videoName,
      versionNumber,
      resolveShowTypeText,
      resolveSendStatusText,
    } = useList()
    fetchChannelList()

    const search = () => {
      loading.value = true
      options.value.page = 1
      fetchList()
    }

    const clickUpload = () => {
      isAddNewSidebarActive.value = true
    }

    return {
      options,
      headers,
      loading,
      dataList,
      channelId,
      videoName,
      totalLists,
      channelName,
      channelList,
      generalData,
      versionNumber,
      isAddNewSidebarActive,
      isPlanUpgradeDialogVisible,
      search,
      fetchList,
      clickUpload,
      videoFilter,
      subtitleFilter,
      thumbnailFilter,
      resolveShowTypeText,
      resolveSendStatusText,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
