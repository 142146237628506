import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/channel/uploadVideos`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannelList() {
      return new Promise((resolve, reject) => {
        axios.get(`${config.serviceUrl}/channel/channelList`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadVideo(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios.post(`${config.serviceUrl}/channel/upload`, itemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
